import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  mutePodcastPlayer,
  changePodcastPlayerVolume,
  returnPodcastPlayerVolume,
  pausePodcastPlayer,
  setPodcastPlayerState,
  stopPodcastPlayer,
} from '../../state/actions/podcastPlayerActions'
import { playPodcastPlayer } from '../../state/actions/playersActions'
import { WrapperContainer } from '../layout/WrapperContainer'
import { MarkCloseButton } from '../../icons/podcastPlatformIcons'

import Slider from '../Slider'
import SeekBar from './seekBar'
import Volume from '../icons/Volume'
import PlayIcon from '../icons/Play'

const mapStateToProps = ({
  players: {
    podcastPlayer: {
      playing,
      volumeValueForPlayer,
      songTitle,
      songName,
      songUrl,
      id,
    },
  },
}) => ({
  playing,
  volumeValueForPlayer,
  songTitle,
  songName,
  songUrl,
  id,
})

const mapDispatchToProps = {
  setPodcastPlayerState,
  mutePodcastPlayer,
  changePodcastPlayerVolume,
  returnPodcastPlayerVolume,
  stopPodcastPlayer,
  playPodcastPlayer,
  pausePodcastPlayer,
}

const PodcastBarPlayer = ({
  playing,
  volumeValueForPlayer,
  songTitle,
  songName,
  songUrl,
  id,
  mutePodcastPlayer,
  changePodcastPlayerVolume,
  returnPodcastPlayerVolume,
  stopPodcastPlayer,
  playPodcastPlayer,
  pausePodcastPlayer,
}) => {
  const infoRef = useRef(null)
  const [infoSize, setInfoSize] = useState({ width: 0, height: 0 })
  const [showSlider, setShowSlider] = useState(false)

  useEffect(() => {
    if (infoRef.current) {
      const { width, height } = infoRef.current.getBoundingClientRect()
      setInfoSize({ width, height })
    }
  }, [songTitle])

  const muteSound = () => mutePodcastPlayer()
  const changeVolume = v => changePodcastPlayerVolume(v)
  const returnVolume = () => returnPodcastPlayerVolume()
  const stopPlayer = () => stopPodcastPlayer()
  const handlePlay = () => {
    playing
      ? pausePodcastPlayer()
      : playPodcastPlayer(id, songUrl, songName, songTitle)
  }
  const handleMouseEnter = () => {
    setShowSlider(true)
  }

  const handleMouseLeave = () => {
    setShowSlider(false)
  }

  return (
    <div className="fixed bg-yellow z-10 top-[70px] left-0 right-0">
      <WrapperContainer
        className="relative items-start md:justify-between gap-6 md:gap-0 flex-col md:flex-row p-4 text-brown md:items-center mx-auto max-w-full md:px-8 md:pr-20 xl:px-8 xl:pr-24 xl:max-w-screen-xl"
        style={{ display: id ? 'flex' : 'none' }}
      >
        <div className="flex gap-4 items-center w-full md:w-auto">
          <button
            className="bg-white flex items-center justify-center w-[2rem] min-w-[2rem] h-[2rem]  rounded-full"
            onClick={handlePlay}
          >
            <PlayIcon playing={playing} fill="#584200" />
          </button>
          <div
            className="flex items-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              onClick={volumeValueForPlayer === 0 ? returnVolume : muteSound}
            >
              <Volume volume={volumeValueForPlayer} fill="#584200" />
            </button>
            {showSlider && (
              <div
                className="mx-2 p-3 bg-white/20 rounded-full"
                style={{ width: `${infoSize.width}px` }}
              >
                <Slider
                  value={volumeValueForPlayer}
                  changeVolume={changeVolume}
                  barColor="#584200"
                  className="w-full"
                />
              </div>
            )}
          </div>
          {!showSlider && (
            <div ref={infoRef} className="flex-col w-full">
              <div className="text-sm leading-4 truncate max-w-full">
                {songTitle}
              </div>
              <div className="font-medium text-xs leading-4 truncate max-w-full">
                {songName}
              </div>
            </div>
          )}
        </div>

        <SeekBar bgGradient="#584200" bgColor="#58420033" className="md:ml-10" />
        <button className="absolute right-5 md:right-8" onClick={stopPlayer}>
          <MarkCloseButton />
        </button>
      </WrapperContainer>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastBarPlayer)
