import {
  SET_PODCAST_PLAYER_STATE,
  MUTE_PODCAST_PLAYER,
  CHANGE_PODCAST_PLAYER_VOLUME,
  RETURN_PODCAST_PLAYER_VOLUME,
  PAUSE_PODCAST_PLAYER,
  STOP_PODCAST_PLAYER,
  SEEK_TO_PODCAST_PLAYER,
} from '../types'

export const setPodcastPlayerState = state => ({
  type: SET_PODCAST_PLAYER_STATE,
  payload: state,
})

export const mutePodcastPlayer = () => ({ type: MUTE_PODCAST_PLAYER })
export const pausePodcastPlayer = () => ({ type: PAUSE_PODCAST_PLAYER })
export const stopPodcastPlayer = () => ({ type: STOP_PODCAST_PLAYER })
export const returnPodcastPlayerVolume = () => ({
  type: RETURN_PODCAST_PLAYER_VOLUME,
})
export const changePodcastPlayerVolume = volume => ({
  type: CHANGE_PODCAST_PLAYER_VOLUME,
  payload: volume,
})
export const seekToPodcastPlayer = newPosition => ({
  type: SEEK_TO_PODCAST_PLAYER,
  payload: newPosition,
})
