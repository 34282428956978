import React, { useState } from 'react'
import { connect } from 'react-redux'
import { toggleForm } from '../state/actions/modalActions'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signUpValidationSchema } from './validation'
import { Input } from './Input'
import { SocialIconsList } from './social/SocialIconsList'
import { Button } from './uiComponent/Button'
import fetchData from '../state/async/auth'
import { signUp } from '../services/auth'

const inputData = [
  {
    label: "Ваше ім'я",
    name: 'username',
    type: 'text',
  },
  {
    label: 'Електронна адреса',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Пароль',
    name: 'password',
    type: 'password',
    validationRules: {
      required: `Пароль обов'язковий`,
      pattern: {
        value:
          /^(?=.*[a-zA-Zа-яА-Яіїєґ])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=\-\\{}[\]:";'<>,.?]).*$/,
        message:
          'Пароль повинен містити одну літеру, одну цифру і один спеціальний символ.',
      },
      minLength: {
        value: 8,
        message: 'Пароль повинен містити від 8 до 30 символів',
      },
      maxLength: {
        value: 30,
        message: 'Пароль повинен містити від 8 до 30 символів',
      },
    },
  },
]

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
})

const mapDispatchToProps = dispatch => {
  return {
    onFetchData: data => dispatch(fetchData(data, signUp)),
    onToggleForm: () => dispatch(toggleForm()),
  }
}

const SignUpForm = ({ onFetchData, onToggleForm }) => {
  const [isRefresh, setIsRefresh] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signUpValidationSchema),
  })

  const onSubmit = async data => {
    setIsRefresh(true)
    await onFetchData(data, signUp)
    setIsRefresh(false)
  }
  const handleClick = () => {
    onToggleForm()
    document.body.classList.add('modal-open')
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full overflow-y-auto"
    >
      <div className="flex flex-col justify-between md:justify-start h-full">
        <div className="mb-16">
          <h3 className="text-5xl mb-7">Реєстрація</h3>
          <ul className="flex flex-col p-1">
            {inputData.map(({ label, name, type }) => (
              <Input
                key={name}
                label={label}
                name={name}
                type={type}
                validationRules={signUpValidationSchema}
                register={register}
                errorAnimation={
                  !!errors[name] ? 'border-b border-red-600 animate-shake' : ''
                }
                errors={errors}
              />
            ))}
          </ul>
          <div>
            <Button
              type="submit"
              className={
                'w-full border-yellow hover:bg-yellow focus:bg-yellow mb-5'
              }
              title="Зареєструватись"
              isRefresh={isRefresh}
            />
          </div>
          <p className="text-center text-xs">
            Уже маєте обліковий запис?{' '}
            <button
              onClick={handleClick}
              className="text-dark-blue cursor-pointer"
            >
              Увійти
            </button>
          </p>
        </div>
        <SocialIconsList />
      </div>
    </form>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
