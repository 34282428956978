import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../state/actions/modalActions'
import { LogoIcon } from './Logo'
import { GrClose } from '@react-icons/all-files/gr/GrClose'

function Modal({ children, dispatch }) {
  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleClick = () => {
    dispatch(toggleModal())
    document.body.classList.remove('modal-open')
  }
  return (
    <div className="flex justify-center items-center h-screen w-screen bg-black/10 fixed z-50 top-0 left-0">
      <div className="w-full h-full flex flex-col md:flex-row md:max-w-6xl md:max-h-[48rem]">
        <div className="bg-background-login p-10 lg:p-24 hidden 2sm:flex flex-col justify-end basis-11/12 bg-[length:auto_80rem] md:bg-[length:auto_100rem] bg-[85rem_54rem] md:bg-[95rem_65rem]">
          <LogoIcon className="fill-white h-11 md:h-16 mr-auto" />
        </div>
        <div className="bg-white relative p-8 2sm:p-10 lg:p-24 w-full h-full">
          <button
            onClick={handleClick}
            className="absolute top-10 2sm:top-14 right-12"
          >
            <GrClose className="w-4 h-4 2sm:w-6 2sm:h-6" />
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default connect()(Modal)
