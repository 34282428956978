import { combineReducers } from 'redux'
import modalReducer from './reducers/modalReducer'
import authReducer from './reducers/authReducer'
import isRefreshReducer from './reducers/refresh'
import userReducer from './reducers/user'
import playersReducer from './reducers/playersReducer'

const rootReducers = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  players: playersReducer,
  refresh: isRefreshReducer,
  user: userReducer,
})

export default rootReducers
