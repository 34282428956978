import * as React from 'react'
import { DEFAULT_VOLUME } from '../../config'

const VolumeIcon = ({
  volume = 0,
  fill='#016DEF',
  defaultVolume = DEFAULT_VOLUME,
  ...props
}) => {
  const VOLUME_PATHS = {
    mute: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0L4 4H0V10H4L9 14V0Z"
          fill={fill}
        />
        <path
          d="M12.54 4.03516L18.4693 9.96484"
          stroke="#016DEF"
          strokeWidth="2"
          strokeLinejoin="round"
          fill={fill}
        />
        <path
          d="M18.4692 4.03516L12.5399 9.96484"
          stroke="#016DEF"
          strokeWidth="2"
          strokeLinejoin="round"
          fill={fill}
        />
      </>
    ),
    low: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0L4 4H0V10H4L9 14V0Z"
          fill={fill}
        />
        <path
          d="M12.8328 4.16794C14.3944 5.72994 14.3944 8.26194 12.8328 9.82394L14.2473 11.2379C16.5895 8.89494 16.5895 5.09694 14.2473 2.75394L12.8328 4.16794Z"
          fill={fill}
        />
      </>
    ),
    high: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0L4 4H0V10H4L9 14V0Z"
          fill={fill}
        />
        <path
          d="M11.8328 4.16794C13.3944 5.72994 13.3944 8.26194 11.8328 9.82394L13.2473 11.2379C15.5895 8.89494 15.5895 5.09694 13.2473 2.75394L11.8328 4.16794Z"
          fill={fill}
        />
        <path
          d="M14.5222 1.50788C17.552 4.53857 17.552 9.45131 14.5222 12.482L15.5383 13.4978C19.129 9.90603 19.129 4.08384 15.5383 0.492118L14.5222 1.50788Z"
          fill={fill}
        />
      </>
    ),
  }

  const getVolumeType = () => {
    if (volume === 0) return 'mute'
    if (volume > defaultVolume) return 'high'
    return 'low'
  }

  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {VOLUME_PATHS[getVolumeType()]}
    </svg>
  )
}

export default VolumeIcon
