import Notiflix from 'notiflix'
import { baseURL } from './config'

async function fetchData(data, path) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    const response = await fetch(`${baseURL}${path}`, options)
    if (!response.ok) {
      throw new Error(handleErrors(response))
    }
    return response.json()
  } catch (error) {
    throw error
  }
}
export function signUp(data) {
  return fetchData(data, 'auth/signUp')
}
export function signIn(data) {
  return fetchData(data, 'auth/signIn')
}

export function signInGoogle(data) {
  return fetchData(data, 'auth/google/login')
}

export async function getIdToken(code) {
  try {
    const options = {
      client_id: process.env.GATSBY_YOUR_CLIENT_ID,
      client_secret: process.env.GATSBY_YOUR_CLIENT_SECRET,
      redirect_uri: process.env.GATSBY_YOUR_REDIRECT_URI,
      code: code,
      grant_type: 'authorization_code',
    }
    const url = 'https://oauth2.googleapis.com/token'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(options).toString(),
    })
    if (!response.ok) {
      throw new Error(`HTTP помилка: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Помилка при отриманні id_token:', error)
    throw error
  }
}

function handleErrors({ status }) {
  switch (status) {
    case 400:
      Notiflix.Notify.warning(
        'Помилка запиту: запит містить помилки або некоректні дані.'
      )
      break
    case 401:
      Notiflix.Notify.warning(
        'Помилка авторизації: ви не авторизовані, або у вас немає доступу до запитуваного ресурсу.'
      )
      break
    case 403:
      Notiflix.Notify.warning(
        'Доступ заборонено: ресурс є приватним і недоступний для загального користування.'
      )
      break
    case 404:
      Notiflix.Notify.warning(
        'Помилка запиту: запитуваний ресурс не знайдено на сервері.'
      )
      break
    case 408:
      Notiflix.Notify.warning('Час очікування відповіді на запит вичерпано.')
      break
    case 500:
      Notiflix.Notify.warning(
        'Внутрішня помилка сервера: сервер не може обробити запит користувача.'
      )
      break
    default:
      Notiflix.Notify.warning('Сталася помилка під час виконання запиту.')
      break
  }
}
