import * as React from 'react'
import {
  TwitterIcon,
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon,
  TelegramIcon,
  SoundCloudIcon,
} from './SocialIcons'

const socialLinks = [
  {
    href: 'https://www.instagram.com/radio_myzukrainy',
    'aria-label': 'Follow on Instagram',
    icon: InstagramIcon,
  },
  {
    href: 'https://www.facebook.com/myzukrainy',
    'aria-label': 'Follow on Facebook',
    icon: FacebookIcon,
  },
  {
    href: 'https://www.youtube.com/channel/UCbKS8Ok0b5krQMekm2UlpBA?sub_confirmation=1',
    'aria-label': 'Follow on YouTube',
    icon: YoutubeIcon,
  },
  // {
  //   href: 'https://t.me/we_are_from_ukraine',
  //   'aria-label': 'Follow on Telegram',
  //   icon: TelegramIcon,
  // },
  {
    href: 'https://soundcloud.com/myzukrainy',
    'aria-label': 'Follow on SoundCloud',
    icon: SoundCloudIcon,
  },
  {
    href: 'https://twitter.com/my_z_ukrainy',
    'aria-label': 'Follow on Twitter',
    icon: TwitterIcon,
  }
]

const SocialLink = ({ icon: Icon, ...props }) => (
  <li>
    <a target="_blank" {...props}>
      <Icon className="h-5 fill-white transition hover:fill-zinc-400" />
    </a>
  </li>
)

const SocialList = () => (
  <>
    {socialLinks.map((item, idx) => (
      <SocialLink key={idx} {...item} />
    ))}
  </>
)
export default SocialList
