import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../../state/actions/modalActions'
import ExitIcon from '../icons/Exit'
import { Logout } from '../../state/actions/authActions'
import { removeAccessToken, removeUser } from '../../state/async/auth'
import SocialList from '../social/SocialList'
import { WrapperContainer } from './WrapperContainer'
import { Container } from './Container'
import imageDefault from '../../../static/img/profile/profile-default-portrait.png'
import { Link } from 'gatsby'
import { fetchUserData } from '../../state/actions/user'
import TopBarPlayer from '../player/topBarPlayer'
import UserIcon from '../icons/UserIcon'

const mapStateToProps = state => ({
  user: state.user,
  isLoggedIn: state.auth.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({
  onFetchUserData: () => dispatch(fetchUserData()),
  logOut: () => dispatch(Logout()),
  toggleModal: () => dispatch(toggleModal()),
})

function TopBar({
  isLoggedIn,
  user,
  isTopBarPlayer,
  onFetchUserData,
  logOut,
  toggleModal,
}) {
  const { username, avatartUrl } = user
  useEffect(() => {
    if (isLoggedIn) {
      onFetchUserData()
    }
  }, [isLoggedIn, onFetchUserData])

  const handleLogin = () => {
    toggleModal()
    document.body.classList.add('modal-open')
  }

  const handleLogout = () => {
    logOut()
    removeAccessToken()
    removeUser()
  }

  return (
    <WrapperContainer className="fixed top-0 left-0 right-0 bg-blue z-10">
      <Container>
        <div className="relative grid text-white py-5 h-[70px]">
          <div className="flex items-center md:justify-end">
            <div className="max-xl:hidden flex md:grow basis-0 text-xs">
              <div>Radio</div>
              <div className="pl-9">+38 068 262 62 03</div>
            </div>
            {isTopBarPlayer && <TopBarPlayer />}
            <div className="flex gap-10 absolute right-0">
              <ul className="max-lg:hidden flex gap-6 items-center">
                <SocialList />
              </ul>

              {isLoggedIn ? (
                <div className="flex gap-4 items-center">
                  <Link to="/profile" className="flex gap-4 items-center">
                    <div className="shrink-0">
                      <img
                        className="object-cover rounded-full md:w-9 h-9 cursor-pointer"
                        src={avatartUrl || imageDefault}
                        alt="Current profile photo"
                      />
                    </div>
                    <p className="hidden lg:block">{username}</p>
                  </Link>
                  <button onClick={handleLogout}>
                    <ExitIcon className="w-4 h-4 fill-white" />
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={handleLogin}
                    className={
                      'h-8 text-xs block lg:border rounded-[3rem] lg:py-[0.5rem] lg:px-[2rem]'
                    }
                  >
                    <UserIcon className="lg:hidden" />
                    <p className="hidden lg:inline">Увійти</p>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </WrapperContainer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
