export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const FAILED = 'FAILED'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const TOGGLE_FORM = 'TOGGLE_FORM'

export const SET_PLAYER_STATE = 'SET_PLAYER_STATE'
export const SET_PODCAST_PLAYER_STATE = 'SET_PODCAST_PLAYER_STATE'

export const MUTE_PLAYER = 'MUTE_PLAYER'
export const CHANGE_PLAYER_VOLUME = 'CHANGE_PLAYER_VOLUME'
export const RETURN_PLAYER_VOLUME = 'RETURN_PLAYER_VOLUME'
export const PAUSE_PLAYER = 'PAUSE_PLAYER'

export const MUTE_PODCAST_PLAYER = 'MUTE_PODCAST_PLAYER'
export const CHANGE_PODCAST_PLAYER_VOLUME = 'CHANGE_PODCAST_PLAYER_VOLUME'
export const RETURN_PODCAST_PLAYER_VOLUME = 'RETURN_PODCAST_PLAYER_VOLUME'
export const PAUSE_PODCAST_PLAYER = 'PAUSE_PODCAST_PLAYER'
export const STOP_PODCAST_PLAYER = 'STOP_PODCAST_PLAYER'
export const SEEK_TO_PODCAST_PLAYER = 'SEEK_TO_PODCAST_PLAYER'

export const PLAY_PLAYER = 'PLAY_PLAYER'
export const PLAY_PODCAST_PLAYER = 'PLAY_PODCAST_PLAYER'