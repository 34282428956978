import React from 'react'
import GoogleAuth from '../GoogleAuth'
// import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
// import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
// const socialDataList = [
//   {
//     name: 'Facebook',
//     icon: <FaFacebookF className="fill-blue-facebook" />
//   },
//   {
//     name: 'Twitter',
//     icon: <FaTwitter className="fill-blue-twitter" />
//   }
// ]
export function SocialIconsList() {
  return (
    <>
      <div className="flex flex-col 2sm:flex-row gap-3">
        <div className="w-full hover:cursor-pointer">
          <GoogleAuth />
        </div>
      </div>
      {/* <ul className="flex flex-col 2sm:flex-row gap-3">
        <li className="w-full hover:cursor-pointer">
          <GoogleAuth />
        </li> */}
      {/* {socialDataList.map(({ name, icon }) => (
          <li key={name} className="w-full hover:cursor-pointer">
            <a className="flex w-full justify-center items-center gap-2 border rounded-[3rem] p-[0.5rem] border-black/10 text-center">
              {icon}
              <span>{name}</span>
            </a>
          </li>
        ))} */}
      {/* </ul> */}
    </>
  )
}
