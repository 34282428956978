import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import he from 'he'

import {
  mutePlayer,
  changePlayerVolume,
  returnPlayerVolume,
  pausePlayer,
} from '../../state/actions/playerActions'
import { playPlayer } from '../../state/actions/playersActions'

import Slider from '../Slider'
import { STREAM_URL, TITLE_UPDATE_INTERVAL } from '../../config'
import Volume from '../icons/Volume'
import PlayIcon from '../icons/Play'

const mapStateToProps = ({
  players: {
    player: { volumeValueForPlayer, playing },
  },
}) => ({
  volumeValueForPlayer,
  playing,
})

const mapDispatchToProps = {
  mutePlayer,
  changePlayerVolume,
  returnPlayerVolume,
  playPlayer,
  pausePlayer,
}

const TopBarPlayer = ({
  volumeValueForPlayer,
  playing,
  mutePlayer,
  changePlayerVolume,
  returnPlayerVolume,
  playPlayer,
  pausePlayer,
}) => {
  const [songTitle, setSongTitle] = useState(null)
  const [showSlider, setShowSlider] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(STREAM_URL.status).then(res => res.json())
        const { source } = res.icestats
        const getTitle = source.title || source[0].title
        const decodingForPlayerTitle = he.decode(getTitle)
        setSongTitle(decodingForPlayerTitle)
      } catch (error) {
        throw error
      }
    }

    const titleIntervalId = setInterval(fetchData, TITLE_UPDATE_INTERVAL)
    fetchData()
    return () => {
      clearInterval(titleIntervalId)
    }
  }, [])

  const handleMuteSound = () => mutePlayer()
  const handleChangeVolume = v => changePlayerVolume(v)
  const handleReturnVolume = () => returnPlayerVolume()
  const handlePlay = () => {
    playing ? pausePlayer() : playPlayer()
  }

  const handleMouseEnter = () => {
    setShowSlider(true)
  }

  const handleMouseLeave = () => {
    setShowSlider(false)
  }
  const [songAuthor, songName] = (songTitle || '').split('-')

  return (
    <div className="absolute left-0 xl:left-1/3">
      <div className="top-bar-player-container">
        <div className="flex gap-4 items-center">
          <button
            className="bg-white flex items-center justify-center w-[2rem] min-w-[2rem] h-[2rem] rounded-full"
            onClick={handlePlay}
          >
            <PlayIcon playing={playing} />
          </button>

          <div
            className="flex items-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="bg-white flex items-center justify-center w-[1.5rem] min-w-[1.5rem] h-[1.5rem] rounded-full"
              onClick={
                volumeValueForPlayer === 0
                  ? handleReturnVolume
                  : handleMuteSound
              }
            >
              <Volume volume={volumeValueForPlayer} />
            </button>
            {showSlider && (
              <div className="bg-white/10 rounded-full p-5 ml-5">
                <div className="max-w-[120px]">
                  <Slider
                    value={volumeValueForPlayer}
                    changeVolume={handleChangeVolume}
                    barColor="#FFFFFF"
                  />
                </div>
              </div>
            )}
          </div>
          {!showSlider && (
            <div className="flex-col max-w-[180px] md:w-auto">
              <div className="text-sm leading-4 truncate max-w-full">
                {songAuthor}
              </div>
              <div className="font-medium text-xs leading-4 truncate max-w-full">
                {songName}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBarPlayer)
