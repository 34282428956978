import React from 'react'

export function Input({
  label,
  name,
  type,
  register,
  validationRules,
  errorAnimation,
  errors,
}) {
  return (
    <li className="flex flex-col max-2sm:h-32 h-32 md:h-32 xl:h-32">
      <label htmlFor={name} className="text-xs opacity-60 px-5">
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        {...register(name, validationRules)}
        className={`border-b focus:border-black placeholder:text-sm 2sm:placeholder:text-base px-5 py-2 focus:outline-none ${errorAnimation}  ${
          errors[name] ? 'focus:border-red-500' : 'focus:border-sky-500'
        }
    ${errors[name] ? 'border-red-500' : 'border-slate-300'}`}
      />
      {errors[name] && (
        <p className="text-red-600 pl-5">{errors[name].message}</p>
      )}
    </li>
  )
}
