const streamURI = 'https://stream4.nadaje.com:9889/'
export const PATH = {
  main: '/',
  music: '/music',
  supportus: '/supportus',
  podcasts: '/podcasts',
  profile: '/profile',
  release: '/podcasts/release',
}
export const STREAM_URL = {  
  main: `${streamURI}lux`,
  nocache: `${streamURI}lux?nocache=`,
  status: `${streamURI}status-json.xsl`
}

export const topBarPlayerPaths = [
  PATH.music,
  PATH.supportus,
  PATH.podcasts,
  PATH.profile,
]

export const TITLE_UPDATE_INTERVAL = 2000
export const DEFAULT_VOLUME = 5
export const SECONDS_IN_MINUTE = 60
export const MILLISECONDS_IN_SECOND = 1000
