import {
  SET_PLAYER_STATE,
  MUTE_PLAYER,
  CHANGE_PLAYER_VOLUME,
  RETURN_PLAYER_VOLUME,
  PAUSE_PLAYER,
} from '../types'

export const returnPlayerVolume = () => ({ type: RETURN_PLAYER_VOLUME })
export const mutePlayer = () => ({ type: MUTE_PLAYER })
export const pausePlayer = () => ({ type: PAUSE_PLAYER })
export const setPlayerState = state => ({
  type: SET_PLAYER_STATE,
  payload: state,
})
export const changePlayerVolume = volume => ({
  type: CHANGE_PLAYER_VOLUME,
  payload: volume,
})
