import {
  CHANGE_PLAYER_VOLUME,
  MUTE_PLAYER,
  PAUSE_PLAYER,
  RETURN_PLAYER_VOLUME,
  SET_PLAYER_STATE,
  PLAY_PLAYER,
} from '../types'

const initialState = {
  url: null,
  pip: false,
  playing: false,
  controls: false,
  light: false,
  volume: 0.5,
  volumeValueForPlayer: 5,
  prevVolume: 0.5,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  width: 0,
  height: 0,
}

const setVolumeValue = volume => {
  return volume * 10
}

export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PLAYER_STATE:
      return {
        ...action.payload,
        volumeValueForPlayer: setVolumeValue(action.payload.volume),
      }
    case MUTE_PLAYER:
      return {
        ...state,
        volume: 0,
        volumeValueForPlayer: 0,
        muted: true,
      }
    case CHANGE_PLAYER_VOLUME:
      return {
        ...state,
        volume: action.payload,
        prevVolume: action.payload,
        volumeValueForPlayer: setVolumeValue(action.payload),
      }
    case RETURN_PLAYER_VOLUME:
      return {
        ...state,
        volume: state.prevVolume || 0.5,
        muted: false,
        volumeValueForPlayer: setVolumeValue(state.prevVolume || 0.5),
      }
    case PLAY_PLAYER:
      return {
        ...state,
        playing: true,
      }
    case PAUSE_PLAYER:
      return {
        ...state,
        playing: false,
      }
    default:
      return state
  }
}
