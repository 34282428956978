import axios from 'axios'
import { baseURL } from './services/config'
import { getAccessToken, getUser } from './state/async/auth'
import { isObj } from './utils'
import Notiflix from 'notiflix'

export const getUserProfile = () => fetch('users/profile')
export const getUserUpdate = (data, id) =>
  fetch(`users/update/${id}`, 'patch', data)
export const getUserAvatar = (data, id) =>
  fetch(`users/avatar/${id}`, 'post', data)
export const removeUserAvatar = id => fetch(`users/avatar/${id}`, 'delete')
export const getListPodcasts = () => fetch('podcasts')
export const getItemPodcasts = (id, page) =>
  fetch(`podcasts/${id}?page=${page}`)
export const getPodcastsReleaseData = id => fetch(`podcasts/release/${id}`)

const fetch = async (url, method = 'get', data) => {
  try {
    const user = getUser()
    const token = getAccessToken()
    const auth = isObj(user) ? { Authorization: `Bearer ${token}` } : {}
    const response = await axios({
      method,
      url,
      baseURL,
      headers: { accept: 'application/json', ...auth },
      data,
    })
    return response.data
  } catch (error) {
    console.error(error)
    if (error.response && error.response.data && error.response.data.message) {
      Notiflix.Notify.warning(error.response.data.message)
    }
  }
}
