import React from 'react'
import { connect } from 'react-redux'
import Modal from './Modal'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'

const mapStateToProps = state => ({
  modalVisible: state.modal.modalVisible,
  currentForm: state.modal.currentForm,
})
function ModalWrapper({ modalVisible, currentForm }) {
  const FormComponent = () => {
    switch (currentForm) {
      case 'SignInForm':
        return <SignInForm />
      case 'SignUpForm':
        return <SignUpForm />
      default:
        return null
    }
  }
  return <>{modalVisible && <Modal>{FormComponent()}</Modal>}</>
}

export default connect(mapStateToProps)(ModalWrapper)
