import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './index'
import { isBrowser } from './async/auth'
import { thunk } from 'redux-thunk'

function savePlayerStateToLocalStorage(playerState) {
  try {
    const playerStateToSave = {
      // isPlaying: playerState.isPlaying,
      // isPause: playerState.isPause,
      // songUrl: playerState.songUrl,
      // songName: playerState.songName,
      // songTitle: playerState.songTitle,
      // savedPlaybackTime: playerState.savedPlaybackTime,
    }
    const serializedState = JSON.stringify(playerStateToSave)
    isBrowser() && localStorage.setItem('persistedPlayerState', serializedState)
  } catch (err) {
    console.error(err)
  }
}

function loadPlayerStateFromLocalStorage() {
  try {
    const serializedState =
      isBrowser() && localStorage.getItem('persistedPlayerState')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

const playersState = loadPlayerStateFromLocalStorage()

const middlewares = [thunk]

const composeEnhancers =
  (isBrowser() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const store = createStore(
  rootReducer,
  {
    players: playersState,
  },
  composeEnhancers(applyMiddleware(...middlewares))
)

store.subscribe(() => {
  const { player } = store.getState()
  savePlayerStateToLocalStorage(player)
})

export const ReduxWrapper = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)
