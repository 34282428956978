import * as React from 'react'
function ExitIcon(props) {
  return (
    <svg version="1.1" id="Capa_1" viewBox="0 0 481.781 481.781" {...props}>
      <g>
        <g>
          <path
            d="M351.596,433.266H23.318V48.515h328.277c6.438,0,11.658-5.221,11.658-11.66c0-6.438-5.223-11.658-11.658-11.658H11.66
			C5.221,25.197,0,30.418,0,36.855v408.069c0,6.438,5.221,11.66,11.66,11.66h339.936c6.438,0,11.658-5.223,11.658-11.66
			S358.033,433.266,351.596,433.266z"
          />
          <path
            d="M478.365,225.474l-71.891-71.902c-4.555-4.555-11.932-4.555-16.486,0c-4.555,4.549-4.555,11.932,0,16.486l51.988,52
			h-217.87c-6.438,0-11.658,5.221-11.658,11.658c0,6.439,5.221,11.66,11.658,11.66h217.87l-51.988,51.986
			c-4.555,4.555-4.555,11.934,0,16.487c2.277,2.275,5.26,3.414,8.244,3.414c2.982,0,5.965-1.139,8.242-3.414l71.891-71.891
			C482.92,237.406,482.92,230.027,478.365,225.474z"
          />
        </g>
      </g>
    </svg>
  )
}
export default ExitIcon
