import { PLAY_PODCAST_PLAYER, PLAY_PLAYER } from '../types'
import playerReducer from './playerReducer'
import podcastPlayerReducer from './podcastPlayerReducer'

const initialState = {
  player: playerReducer(undefined, {}),
  podcastPlayer: podcastPlayerReducer(undefined, {}),
}

export default function playersReducer(state = initialState, action) {
  const newState = {
    player: playerReducer(state.player, action),
    podcastPlayer: podcastPlayerReducer(state.podcastPlayer, action),
  }

  switch (action.type) {
    case PLAY_PLAYER:
      if (newState.player.playing) {
        newState.podcastPlayer = { ...newState.podcastPlayer, playing: false }
      }
      break
    case PLAY_PODCAST_PLAYER:
      if (newState.podcastPlayer.playing) {
        newState.player = { ...newState.player, playing: false }
      }
      break
    default:
      break
  }

  return newState
}
