import * as React from 'react'

const MAX = 10

const Slider = ({ changeVolume, value, className, barColor }) => {
  const getBackgroundSize = (value, max) => {
    return {
      backgroundSize: `${(value * 100) / max}% 100%`,
      backgroundImage: `linear-gradient(${barColor}, ${barColor})`,
      backgroundRepeat: 'no-repeat',
    }
  }

  return (
    <div className="relative flex items-center w-full">
      <input
        type="range"
        step="0.5"
        min="0"
        max={MAX}
        onChange={e => {
          changeVolume(e.target.value / 10)
        }}
        value={value}
        className={`w-full h-1 appearance-none cursor-pointer ${className}`}
        style={{
          backgroundColor: `${barColor}33`,
          WebkitAppearance: 'none',
          appearance: 'none',
          ...getBackgroundSize(value, MAX),
        }}
      />
    </div>
  )
}

export default Slider
