import { connect } from 'react-redux'
import React from 'react'
import {
  setPodcastPlayerState,
  seekToPodcastPlayer,
} from '../../state/actions/podcastPlayerActions'

const mapStateToProps = state => ({
  podcastPlayer: state.players.podcastPlayer,
})

const mapDispatchToProps = {
  setPodcastPlayerState,
  seekToPodcastPlayer,
}

const SeekBar = ({
  podcastPlayer,
  seekToPodcastPlayer,
  bgGradient,
  bgColor,
  className,
}) => {
  const { played } = podcastPlayer

  const getBackgroundSize = (value, max) => {
    return {
      backgroundSize: `${(value * 100) / max}% 100%`,
      backgroundImage: `linear-gradient(${bgGradient}, ${bgGradient})`,
      backgroundRepeat: 'no-repeat',
    }
  }

  const handleSeekChange = e => {
    const newPlayed = parseFloat(e.target.value)
    seekToPodcastPlayer(newPlayed)
  }

  return (
    <div className="flex items-center w-full">
      <input
        type="range"
        step="any"
        min={0}
        max={0.999999}
        onChange={handleSeekChange}
        value={played}
        className={`w-full h-1 appearance-none cursor-pointer ${className}`}
        style={{
          backgroundColor: `${bgColor}`,
          ...getBackgroundSize(played, 1),
        }}
      />
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SeekBar)
