import React from 'react'

const UserIcon = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="12" cy="8" r="5.25" stroke="white" strokeWidth="1.5" />
    <path
      d="M20.6 22H22C22 17.0294 17.5228 13 12 13C6.47715 13 2 17.0294 2 22H3.4C3.4 17.9377 7.10795 14.4 12 14.4C16.892 14.4 20.6 17.9377 20.6 22Z"
      fill="white"
    />
  </svg>
)

export default UserIcon
