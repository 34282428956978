import * as React from 'react'

const RadioMenu = ({ color = '#0273FF' }) => (
  <svg
    width="58"
    height="40"
    viewBox="0 0 58 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5417 27.4484L46.6855 22.4104C47.5603 21.2327 48.1932 19.8952 48.5482 18.4742C48.9031 17.0532 48.9731 15.5766 48.7541 14.1287C48.5351 12.6808 48.0314 11.29 47.2719 10.0357C46.5124 8.78146 45.5118 7.68829 44.3274 6.81866L49.3931 0C51.4854 1.5321 53.2531 3.45997 54.5946 5.67305C55.9361 7.88612 56.8251 10.3408 57.2105 12.8964C57.596 15.4519 57.4703 18.058 56.8407 20.5651C56.2111 23.0722 55.09 25.4311 53.5417 27.5063V27.4484Z"
      fill="#FEC20D"
    />
    <path
      d="M17.1918 29.4345L4.89561 29.4345L8.43334e-06 16.6885L11.0814 16.6885L17.1918 29.4345Z"
      fill={color}
    />
    <path
      d="M29.3978 29.4352H17.1913L12.2957 6.87891H23.2874L29.3978 29.4352Z"
      fill={color}
    />
    <path
      d="M41.6067 29.4346H29.3981L26.2305 2.4082H37.2121L41.6067 29.4346Z"
      fill={color}
    />
    <path
      d="M17.1919 29.4346C17.7657 32.4062 19.3623 35.0864 21.7073 37.0145C24.0524 38.9427 26.9994 39.9984 30.0422 40.0004H31.9376L29.3988 29.4346H17.1919Z"
      fill={color}
    />
  </svg>
)

export default RadioMenu
