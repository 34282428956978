import React from 'react'
import { FcGoogle } from '@react-icons/all-files/fc/FcGoogle'

function GoogleAuth() {
  const oauth2Endpoint = `https://accounts.google.com/o/oauth2/v2/auth`
  const options = {
    redirect_uri: process.env.GATSBY_YOUR_REDIRECT_URI,
    client_id: process.env.GATSBY_YOUR_CLIENT_ID,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      'openid',
    ].join(' '),
  }
  const qs = new URLSearchParams(options)
  const googleUrl = `${oauth2Endpoint}?${qs.toString()}`
  return (
    <a
      href={googleUrl}
      className="flex w-full justify-center items-center gap-2 border rounded-[3rem] p-[0.5rem] border-black/10 text-center"
    >
      <FcGoogle />
      <span>Google</span>
    </a>
  )
}

export default GoogleAuth
