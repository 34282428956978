import './src/styles/global.css'
import { ReduxWrapper } from './src/state/store'

export const onRouteUpdate = () => {
  if (typeof window !== 'undefined') {
    const event = new CustomEvent('closeMobileMenu')
    window.dispatchEvent(event)
  }
}
export const wrapRootElement = ReduxWrapper
