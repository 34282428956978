import * as React from 'react'

const PlayIcon = ({ playing, fill = '#0968D8', ...props }) => {
  const BUTTON_PATHS = {
    play: (
      <>
        <path d="M12 7L0 0V14L12 7Z" fill={fill} />
      </>
    ),
    pause: (
      <>
        <path
          d="M2 0V16"
          stroke={fill}
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M10 0C10 6.24839 10 16 10 16"
          stroke={fill}
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </>
    ),
  }

  const getButtonType = () => {
    return playing ? 'pause' : 'play'
  }

  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {BUTTON_PATHS[getButtonType()]}
    </svg>
  )
}

export default PlayIcon
