import * as React from 'react'
import { Helmet } from 'react-helmet'
import Header from './Header'
import TopBar from './TopBar'
import { Container } from './Container'
import ModalWrapper from '../ModalWrapper'
import Player from '../player/player'
import PodcastBarPlayer from '../player/podcastBarPlayer'
import { Footer } from './Footer'
import PodcastPlayer from '../player/podcastPlayer'
import { topBarPlayerPaths, PATH } from '../../config'

const Layout = ({ children, path }) => {
  const shouldShowTopBarPlayers = topBarPlayerPaths.some(prefix =>
    path.startsWith(prefix)
  )
  const shouldShowPodcastBar = !path.startsWith(PATH.release)
  return (
    <>
      <header>
        <TopBar isTopBarPlayer={shouldShowTopBarPlayers} />
        {shouldShowPodcastBar && <PodcastBarPlayer />}
        <Container>
          <Header shouldShowPodcastBar={shouldShowPodcastBar} />
        </Container>
        <Helmet>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6840362780258450" crossOrigin="anonymous"></script>
        </Helmet>
      </header>
      <main>
        <Container>{children}</Container>
      </main>
      {shouldShowTopBarPlayers && (
        <footer>
          <Container>
            <Footer />
          </Container>
        </footer>
      )}
      <Player />
      <PodcastPlayer />
      <ModalWrapper />
    </>
  )
}
export default Layout
