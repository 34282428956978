import * as React from 'react'

export const LogoIcon = props => (
  <svg viewBox="0 0 124 29" aria-hidden="true" {...props}>
    <path d="M4.67083 0.780252H0.739746V10.1753H2.29734V6.59034H4.67083C6.7229 6.59034 8.00854 5.47777 8.00854 3.67294C8.00854 1.89282 6.7229 0.780252 4.67083 0.780252ZM4.47304 5.25526H2.29734V2.14006H4.47304C5.75868 2.14006 6.42622 2.65926 6.42622 3.69766C6.42622 4.71133 5.75868 5.25526 4.47304 5.25526Z" />
    <path d="M15.4257 8.88966C15.1043 8.88966 14.9559 8.74131 14.9559 8.4199V5.08219C14.9559 3.54932 13.8681 2.63454 12.088 2.63454C10.8023 2.63454 9.73921 3.07956 8.87387 3.89545L9.78865 4.85968C10.382 4.24158 11.1732 3.87073 12.0138 3.87073C12.9533 3.87073 13.5714 4.26631 13.5714 4.85968C13.5714 5.42832 13.0275 5.52722 11.9149 5.67556C10.4809 5.89808 8.72553 6.29366 8.72553 8.12322C8.72553 9.40885 9.68976 10.2989 11.1485 10.2989C12.2363 10.2989 13.2253 9.80444 13.7445 9.038C13.8434 9.90333 14.2637 10.2247 15.1784 10.2247C15.4257 10.2247 15.6235 10.2 15.6976 10.1753V8.88966H15.4257ZM11.3957 9.08745C10.6293 9.08745 10.1842 8.71659 10.1842 8.02432C10.1842 7.0601 11.1485 6.91175 12.088 6.76341C12.805 6.63979 13.25 6.56562 13.5219 6.39255V7.35678C13.5219 8.29628 12.533 9.08745 11.3957 9.08745Z" />
    <path d="M23.7823 8.86493V2.75815H17.7003V5.84863C17.7003 7.30733 17.6261 8.27156 17.4778 8.86493H16.6124V11.906H18.0217V10.1753H23.362V11.906H24.7713V8.86493H23.7823ZM18.9117 8.86493C19.0601 8.12322 19.1342 7.10954 19.1342 5.9228V4.06852H22.3236V8.86493H18.9117Z" />
    <path d="M26.4525 1.71976H28.0843V0.236328H26.4525V1.71976ZM26.5267 10.1753H28.0101V2.75815H26.5267V10.1753Z" />
    <path d="M33.6718 10.2989C35.897 10.2989 37.4298 8.74131 37.4298 6.46672C37.4298 4.14269 35.9464 2.63454 33.6718 2.63454C31.4467 2.63454 29.9138 4.19214 29.9138 6.46672C29.9138 8.79076 31.3972 10.2989 33.6718 10.2989ZM33.6718 9.038C32.312 9.038 31.422 8.02432 31.422 6.46672C31.422 4.90913 32.312 3.89545 33.6718 3.89545C35.0316 3.89545 35.9217 4.90913 35.9217 6.46672C35.9217 8.02432 35.0316 9.038 33.6718 9.038Z" />
    <path d="M15.9119 13.1035H11.7913L8.49478 22.1689L5.1653 13.1035H1.01171V25.6302H3.78077V16.7956L7.01135 25.6302H9.91227L13.1428 16.7956V25.6302H15.9119V13.1035Z" />
    <path d="M25.5707 15.7407L21.516 21.8392V15.7407H18.9447V25.6302H21.5489L25.6036 19.5317V25.6302H28.1749V15.7407H25.5707Z" />
    <path d="M42.251 20.5206C43.0421 20.0921 43.4707 19.3998 43.4707 18.4768C43.4707 16.7296 41.9213 15.5759 39.5808 15.5759C37.7018 15.5759 36.1854 16.3341 35.2954 17.6527L37.0425 19.0042C37.57 18.1471 38.3941 17.6856 39.416 17.6856C40.372 17.6856 40.9324 18.0482 40.9324 18.7075C40.9324 19.3339 40.4049 19.7295 39.6467 19.7295H38.3611V21.5425H39.7127C40.6687 21.5425 41.2291 21.9381 41.2291 22.5645C41.2291 23.2567 40.5368 23.6853 39.449 23.6853C38.3281 23.6853 37.4711 23.1908 36.9766 22.2348L34.9657 23.3556C35.7569 24.9379 37.4051 25.795 39.416 25.795C42.0202 25.795 43.8663 24.5424 43.8663 22.7952C43.8663 21.7403 43.2729 20.9162 42.251 20.5206Z" />
    <path d="M59.0632 13.1035L56.5249 20.3888L52.8657 13.1035H49.7011L55.3711 23.1249C55.1403 23.4545 54.8107 23.5534 54.4481 23.5534C53.7558 23.5534 53.3602 23.0589 53.3272 22.1359L51.1186 22.6963C51.1845 24.4105 52.4042 25.795 54.3162 25.795C56.426 25.795 57.4808 24.4435 58.239 22.4656L61.9971 13.1035H59.0632Z" />
    <path d="M69.3812 25.6302H72.4799L68.4252 19.6306L72.3481 15.7407H69.0516L65.2936 19.6965V15.7407H62.6564V25.6302H65.2936V22.6963L66.5792 21.4436L69.3812 25.6302Z" />
    <path d="M79.6663 15.5759C78.3148 15.5759 77.2269 16.1363 76.4687 17.1252L76.3369 15.7407H73.9634V28.9267H76.6006V24.4764C77.3588 25.3005 78.3807 25.795 79.6663 25.795C82.1717 25.795 83.8858 23.7512 83.8858 20.6854C83.8858 17.5867 82.2376 15.5759 79.6663 15.5759ZM78.8422 23.6193C77.3588 23.6193 76.5017 22.4985 76.5017 20.6854C76.5017 18.8724 77.3588 17.7516 78.8422 17.7516C80.3256 17.7516 81.2157 18.8724 81.2157 20.6854C81.2157 22.4985 80.3256 23.6193 78.8422 23.6193Z" />
    <path d="M94.3028 23.3556C93.9402 23.3556 93.7424 23.1578 93.7424 22.7952V18.9053C93.7424 16.8285 92.226 15.5759 89.6547 15.5759C87.8746 15.5759 86.3912 16.1363 85.2045 17.2571L86.7868 18.8724C87.545 18.1142 88.468 17.7186 89.4899 17.7186C90.6107 17.7186 91.27 18.1471 91.27 18.7735C91.27 19.3668 90.38 19.5317 89.2591 19.6635C87.4131 19.8943 85.0067 20.3558 85.0067 22.9271C85.0067 24.6412 86.2923 25.795 88.2372 25.795C89.6877 25.795 90.9733 25.2017 91.6656 24.2127C91.8634 25.2346 92.5227 25.6961 93.7424 25.6961C94.2369 25.6961 94.5006 25.6632 94.6984 25.6302V23.3556H94.3028ZM88.8306 23.7182C88.0065 23.7182 87.545 23.3226 87.545 22.6633C87.545 21.7074 88.501 21.5425 89.5229 21.3777C90.2481 21.2458 90.8085 21.147 91.2041 20.9162V21.7733C91.2041 22.8611 90.1492 23.7182 88.8306 23.7182Z" />
    <path d="M94.8962 14.488H97.5993V12.2464H94.8962V14.488ZM98.7201 14.488H101.423V12.2464H98.7201V14.488ZM96.8411 25.6302H99.4783V15.7407H96.8411V25.6302Z" />
    <path d="M108.873 15.7407V19.3998H104.983V15.7407H102.346V25.6302H104.983V21.7733H108.873V25.6302H111.511V15.7407H108.873Z" />
    <path d="M120.84 15.7407L116.785 21.8392V15.7407H114.214V25.6302H116.818L120.873 19.5317V25.6302H123.444V15.7407H120.84Z" />
  </svg>
)
