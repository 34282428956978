import { getUserProfile, getUserUpdate } from '../../fetch'

export const fetchUserData = () => {
  return async dispatch => {
    const userData = await getUserProfile()
    dispatch({
      type: 'FETCH_USER_DATA',
      payload: userData,
    })
  }
}

export const updateUserData = (data, id) => {
  return async dispatch => {
    const updatedUserData = await getUserUpdate(data, id)
    dispatch({
      type: 'UPDATE_USER_DATA',
      payload: updatedUserData,
    })
  }
}

export const refreshUserData = reset => ({
  type: 'REFRESH_USER_DATA',
  payload: reset,
})
