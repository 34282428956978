import React from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'

import { STREAM_URL } from '../../config'
import { isBrowser } from '../../utils'

const mapStateToProps = state => ({
  player: state.players.player,
})

const Player = ({ player }) => {
  const { playing, width, height, volume } = player
  if (isBrowser) {
    return createPortal(
      <ReactPlayer
        url={STREAM_URL.nocache}
        playing={playing}
        width={width}
        height={height}
        volume={volume}
        config={{ forceAudio: true }}
      />,
      window.document?.body
    )
  }
}

export default connect(mapStateToProps)(Player)
