import * as yup from 'yup'

export const commonUserValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(
      /^[А-ЯІЇЄҐа-яіїєґA-Za-z' -]+$/,
      'Тільки кирилиця або латиниця, без символів та цифр'
    )
    .min(3, "Ім'я повинно містити від 3 до 30 значень")
    .max(20, "Ім'я повинно містити від 3 до 30 значень")
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
  newPassword: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[a-zA-Zа-яА-Яіїєґ])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=\-\\{}[\]:";'<>,.?]).*$/,
      'Пароль повинен містити одну літеру, одну цифру і один спеціальний символ.'
    )
    .test('len', 'Пароль повинен містити від 8 до 30 символів', val => {
      if (val === null) {
        return true
      }
      return val.length >= 8 && val.length <= 30
    })
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword')], 'Пароль не збігається!')
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
  oldPassword: yup
    .string()
    .trim()
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
})

export const googleUserValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(
      /^[А-ЯІЇЄҐа-яіїєґA-Za-z\s'-]*[А-ЯІЇЄҐа-яіїєґA-Za-z]+[А-ЯІЇЄҐа-яіїєґA-Za-z\s'-]*$/,
      'Тільки кирилиця або латиниця, без цифр та символів'
    )
    .min(3, "Ім'я повинно містити від 3 до 30 значень")
    .max(20, "Ім'я повинно містити від 3 до 30 значень")
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
  createPassword: yup
    .string()
    .trim()
    .matches(
      /^(?=.*[a-zA-Zа-яА-Яіїєґ])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=\-\\{}[\]:";'<>,.?]).*$/,
      'Пароль повинен містити одну літеру, одну цифру і один спеціальний символ.'
    )
    .test('len', 'Пароль повинен містити від 8 до 30 символів', val => {
      if (val === null) {
        return true
      }
      return (
        val === null ||
        (typeof val !== 'undefined' && val.length >= 8 && val.length <= 30)
      )
    })
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
  confirmNewPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('createPassword')], 'Пароль не збігається!')
    .nullable(true)
    .transform((value, originalValue) => (!originalValue ? null : value)),
})

export const avatarUserValidationSchema = yup.object().shape({
  file: yup
    .mixed()
    .test('fileSize', 'Максимальний розмір файлу - 1MB', value => {
      if (!value || !value[0]) return true
      return value[0].size <= 1000000
    })
    .test('fileType', 'Дозволено лише PNG та JPEG', value => {
      if (!value || !value[0]) return true
      const acceptedFormats = ['image/jpeg', 'image/png']
      return acceptedFormats.includes(value[0].type)
    }),
})

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Електронна адреса обов'язкова")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Введіть коректну електронну адресу'
    ),
  password: yup
    .string()
    .trim()
    .required("Пароль обов'язковий")
    .min(8, 'Пароль повинен містити від 8 до 30 символів')
    .max(30, 'Пароль повинен містити від 8 до 30 символів'),
})

export const signUpValidationSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required(`Ім'я обов'язкове`)
    .matches(
      /^[А-ЯІЇЄҐа-яіїєґA-Za-z' -]+$/,
      'Тільки кирилиця або латиниця, без символів та цифр'
    )
    .min(3, "Ім'я повинно містити від 3 до 30 значень")
    .max(30, "Ім'я повинно містити від 3 до 30 значень"),
  email: yup
    .string()
    .trim()
    .required(`Електронна адреса обов'язкова`)
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Введіть коректну електронну адресу'
    ),
  password: yup
    .string()
    .trim()
    .required(`Пароль обов'язковий`)
    .matches(
      /^(?=.*[a-zA-Zа-яА-Яіїєґ])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=\-\\{}[\]:";'<>,.?]).*$/,
      'Пароль повинен містити одну літеру, одну цифру і один спеціальний символ.'
    )
    .min(8, 'Пароль повинен містити від 8 до 30 символів')
    .max(30, 'Пароль повинен містити від 8 до 30 символів'),
})
