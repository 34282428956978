import React from 'react'
import {
  BroadcastList,
  PodcastList,
  SocialList,
} from '../../icons/footerIconsList'
import { Link } from 'gatsby'

const dataListsRoutes = [
  {
    title: 'Музика',
    link: '/music',
  },
  {
    title: 'Подкасти',
    link: '/podcasts',
  },
  {
    title: 'Підтримати нас',
    link: '/supportus',
  },
]

export const Footer = () => (
  <div className="py-5">
    <div className="xl:flex xl:justify-between xl:pb-7 md:pt-7 md:border-y md:border-gray-300">
      <ul className="flex flex-wrap justify-center gap-y-7 gap-x-10 ">
        {dataListsRoutes.map(({ title, link }, idx) => (
          <li key={idx} className="w-36 text-center xl:flex xl:items-start">
            <Link href={link}>
              <h2 className="text-base mb-3">{title}</h2>
            </Link>
          </li>
        ))}
      </ul>
      <ul className="flex flex-wrap justify-center py-7 gap-x-10 xl:p-0">
        <li className="">
          <h3 className="text-base mb-4">Слухати ефір</h3>
          <BroadcastList />
        </li>
        <li className="">
          <h3 className="text-base mb-4">Слухати подкасти</h3>
          <PodcastList />
        </li>
      </ul>
    </div>
    <div className="flex flex-wrap justify-center xl:justify-end items-center md:pt-7">
      <SocialList />
    </div>
  </div>
)
