import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import RadioMenu from '../icons/RadioMenu'
import { LogoIcon } from '../Logo'
import CloseIcon from '../icons/CloseIcon'
import Navigate from './Navigate'
import SocialList from '../social/SocialList'
import { PATH } from '../../config'

const MobileMenu = ({ setShowMobile }) => {
  useEffect(() => {
    const handleRouteChange = () => setShowMobile(false)
    window.addEventListener('closeMobileMenu', handleRouteChange)

    return () => {
      window.removeEventListener('closeMobileMenu', handleRouteChange)
    }
  }, [])
  return (
    <div className="text-white p-5 bg-[#0273FF] overflow-y-auto mobile-menu z-10 fixed top-[70px] left-0 right-0 bottom-0">
      <div className="flex justify-between ">
        <Link to={PATH.main} className="w-max flex items-start">
          <RadioMenu color="#fff" />
          <LogoIcon className="fill-white w-[122px] ml-4" />
        </Link>
        <button className="cursor-pointer" onClick={() => setShowMobile(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col justify-between h-[calc(100vh-150px)]">
        <div>
          <Navigate
            className="flex mt-[3rem] mb-[3rem] flex-col text-[40px] font-medium justify-center "
            itemClassName="[&:not(:nth-last-child(-n+2))]:mb-6"
          >
            <li className="leading-4">
              <a
                href="tel:+38 067 123 45 67"
                className="text-xl mt-4 opacity-70"
              >
                +38 067 123 45 67
              </a>
            </li>
          </Navigate>
        </div>

        <div className="flex flex-col items-center pb-4">
          <ul className="flex gap-3 mb-[1.5rem]">
            <SocialList />
          </ul>
          <Link
            to={PATH.supportus}
            className="font-normal flex items-center justify-center w-full max-w-[21rem] text-[#584200] px-4 py-2 border-1 border-transparent rounded-full bg-yellow hover:bg-white hover:border-yellow"
          >
            Підтримати
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
