import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import { setPodcastPlayerState } from '../../state/actions/podcastPlayerActions'
import { isBrowser } from '../../utils'

const mapStateToProps = ({ players }) => ({
  podcastPlayer: players.podcastPlayer,
})

const mapDispatchToProps = dispatch => ({
  setPodcastPlayerState: state => dispatch(setPodcastPlayerState(state)),
})

const PodcastPlayer = ({ podcastPlayer, setPodcastPlayerState }) => {
  const playerRef = useRef(null)

  const { playing, width, height, volume, songUrl, played, seekTo } =
    podcastPlayer

  useEffect(() => {
    if (playerRef.current && seekTo !== null) {
      playerRef.current.seekTo(seekTo, 'fraction')
      setPodcastPlayerState({ ...podcastPlayer, seekTo: null })
    }
  }, [seekTo])

  const handleProgress = ({ played }) => {
    setPodcastPlayerState({ ...podcastPlayer, played })
  }

  if (!isBrowser) {
    return null
  }

  return createPortal(
    <ReactPlayer
      ref={playerRef}
      url={songUrl}
      playing={playing}
      width={width}
      height={height}
      volume={volume}
      played={played}
      onProgress={handleProgress}
      config={{ forceAudio: true }}
    />,
    window.document?.body
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastPlayer)
