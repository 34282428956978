import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from './config'

export const groupSongsByAuthor = songs => {
  const groupedSongs = {}
  songs
    .sort((a, b) => a.frontmatter.author.localeCompare(b.frontmatter.author))
    .forEach(node => {
      const firstLetter = node.frontmatter.author[0].toUpperCase()
      if (!groupedSongs[firstLetter]) groupedSongs[firstLetter] = []
      groupedSongs[firstLetter].push(node)
    })
  return groupedSongs
}
export const getDecodedTitle = title => {
  const s = `<p id="s">${title}</p>`
  const htmlObject = document.createElement('p')
  htmlObject.innerHTML = s
  return htmlObject.textContent
}
export const formatPodcastData = releases => {
  return releases.map(
    ({
      author,
      _id,
      imgUrl,
      date,
      duration,
      title,
      url,
      about,
      text,
      slug,
    }) => {
      const inputDate = new Date(date)
      const monthNames = [
        'січня',
        'лютого',
        'березня',
        'квітня',
        'травня',
        'червня',
        'липня',
        'серпня',
        'вересня',
        'жовтня',
        'листопада',
        'грудня',
      ]
      // date formater
      const day = inputDate.getDate().toString().padStart(2, '0')
      const month = monthNames[inputDate.getMonth()]
      const formattedDate = `${day} ${month} `
      // time formater
      const totalSeconds = duration / 1000
      const minutes = Math.round(totalSeconds / 60)
      const decimalPart = totalSeconds / 60 - minutes
      const seconds = Math.round(decimalPart * 100)
      const formattedDuration = `${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      return {
        author,
        _id,
        imgUrl,
        date: formattedDate,
        duration: formattedDuration,
        slug,
        title,
        url,
        about,
        text,
      }
    }
  )
}
export const isBrowser = typeof window !== 'undefined'
export const isObj = obj => Object.keys(obj).length !== 0
export const truncateText = (text, maxLength) => {
  if (!text) return ''
  if (text.length <= maxLength) {
    return text
  }
  return `${text.substring(0, maxLength)}...`
}

export const convertToEmbed = (url = '') => {
  if (!url) return
  const embedUrl = 'https://www.youtube.com/embed/'
  const urlObj = new URL(url)
  const videoId = urlObj.searchParams.get('v')
  return videoId ? embedUrl + videoId : null
}

export const formatDuration = duration => {
  const totalSeconds = Math.floor(duration / MILLISECONDS_IN_SECOND)
  const minutes = Math.floor(totalSeconds / SECONDS_IN_MINUTE)
  const seconds = totalSeconds % SECONDS_IN_MINUTE

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2,'0')}`
}
