import React from 'react'

const CloseIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L9 9M17 17L9 9M9 9L17 1L1 17"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)

export default CloseIcon
