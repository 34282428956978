import React from 'react'
import { Link } from 'gatsby'
import { PATH } from '../../config'

const navigation = [
  { name: 'Музика', href: PATH.music },
  { name: 'Подкасти', href: PATH.podcasts },
]

const Navigate = ({ className, children, itemClassName, linkClassName }) => (
  <ul className={className}>
    {navigation.map(({ name, href }) => (
      <li className={itemClassName} key={name}>
        <Link className={linkClassName} to={href}>
          {name}
        </Link>
      </li>
    ))}
    {children}
  </ul>
)

export default Navigate
