import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { toggleForm } from '../state/actions/modalActions'
import { yupResolver } from '@hookform/resolvers/yup'
import { signInValidationSchema } from './validation'
import { Input } from './Input'
import { SocialIconsList } from './social/SocialIconsList'
import { Button } from './uiComponent/Button'
import fetchData from '../state/async/auth'
import { signIn } from '../services/auth'

const inputData = [
  {
    label: 'Електронна адреса',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Пароль',
    name: 'password',
    type: 'password',
  },
]

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  errorFetch: state.auth.error,
})

const mapDispatchToProps = dispatch => {
  return {
    onFetchData: data => dispatch(fetchData(data, signIn)),
    onToggleForm: () => dispatch(toggleForm()),
  }
}

const SignInForm = ({ onFetchData, onToggleForm }) => {
  const [isRefresh, setIsRefresh] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signInValidationSchema),
  })

  const onSubmit = async data => {
    setIsRefresh(true)
    await onFetchData(data)
    setIsRefresh(false)
  }
  const handleClick = () => {
    onToggleForm()
    document.body.classList.add('modal-open')
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <div className="flex flex-col justify-between md:justify-start h-full">
          <div className="mb-16">
            <h3 className="text-5xl mb-7">Вхід</h3>
            <ul className="flex flex-col p-1">
              {inputData.map(({ label, name, type }) => (
                <Input
                  key={name}
                  label={label}
                  name={name}
                  type={type}
                  register={register}
                  validationRules={signInValidationSchema}
                  errorAnimation={
                    !!errors[name]
                      ? 'border-b border-red-600 animate-shake'
                      : ''
                  }
                  errors={errors}
                />
              ))}
            </ul>
            <div>
              <Button
                type="submit"
                className={
                  'w-full border-yellow hover:bg-yellow focus:bg-yellow mb-5'
                }
                title="Увійти"
                isRefresh={isRefresh}
              />
            </div>
            <p className="text-center text-xs">
              У Вас ще немає облікового запису?{' '}
              <button
                onClick={handleClick}
                className="text-dark-blue cursor-pointer"
              >
                Зареєструватися
              </button>
            </p>
          </div>
          <SocialIconsList />
        </div>
      </form>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(SignInForm)
