import * as React from 'react'
import {
  SoundCloudIcon,
  YoutubeIcon,
  ITunesIcon,
  GooglePlay,
  OnlineRadioBox,
  TelegramIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  RadioPlayerIcon,
} from './podcastPlatformIcons'

const podcastData = [
  {
    href: 'https://www.youtube.com/@myzukrainy',
    'aria-label': 'Follow on Youtube',
    icon: YoutubeIcon,
  },
  {
    href: 'https://soundcloud.com/myzukrainy/sets',
    'aria-label': 'Follow on SoundCloud',
    icon: SoundCloudIcon,
  },
  {
    href: 'https://apple.co/3uMwjqO',
    'aria-label': 'Follow on ITunes',
    icon: ITunesIcon,
  },
  {
    href: 'https://bit.ly/3iLIazC',
    'aria-label': 'Follow on GooglePlay',
    icon: GooglePlay,
  },
]

const broadcastData = [
  {
    href: 'https://radioplayer.ua',
    'aria-label': 'Follow on RadioPlayer',
    icon: RadioPlayerIcon,
  },
  // {
  //   href: '#',
  //   'aria-label': 'Follow on RadioLive',
  //   icon: RadioLive
  // },
  {
    href: 'https://onlineradiobox.com/ua/myzukrainy',
    'aria-label': 'Follow on OnlineRadioBox',
    icon: OnlineRadioBox,
  },
]

const socialData = [
  {
    href: 'https://www.instagram.com/radio_myzukrainy',
    'aria-label': 'Follow on Instagram',
    icon: InstagramIcon,
  },
  {
    href: 'https://www.facebook.com/myzukrainy',
    'aria-label': 'Follow on Facebook',
    icon: FacebookIcon,
  },
  {
    href: 'https://twitter.com/my_z_ukrainy',
    'aria-label': 'Follow on Twitter',
    icon: TwitterIcon,
  }
  // {
  //   href: 'https://t.me/we_are_from_ukraine',
  //   'aria-label': 'Follow on Telegram',
  //   icon: TelegramIcon,
  // },
]

const PodcastLink = ({ icon: Icon, ...props }) => (
  <a target="_blank" {...props}>
    <Icon className="w-8 h-8" />
  </a>
)
export const PodcastList = () => (
  <ul className="flex">
    {podcastData.map((item, idx) => (
      <li key={idx} className="[&:not(:last-child)]:mr-3">
        <PodcastLink {...item} />
      </li>
    ))}
  </ul>
)
const BroadcastLink = ({ icon: Icon, ...props }) => (
  <a target="_blank" {...props}>
    <Icon className="w-8 h-8" />
  </a>
)
export const BroadcastList = () => (
  <ul className="flex">
    {broadcastData.map((item, idx) => (
      <li key={idx} className="[&:not(:last-child)]:mr-3">
        <BroadcastLink {...item} />
      </li>
    ))}
  </ul>
)
const SocialLink1 = ({ icon: Icon, ...props }) => (
  <a target="_blank" {...props}>
    <Icon className="w-5 h-5" />
  </a>
)
export const SocialList = () => (
  <ul className="flex pb-4">
    {socialData.map((item, idx) => (
      <li key={idx} style={{ marginRight: '10px' }}>
        <SocialLink1 {...item} />
      </li>
    ))}
  </ul>
)
