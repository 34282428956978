import * as React from 'react'

const DoubleBurger = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M0 1L24 0.999998"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M0 11L24 11"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default DoubleBurger
