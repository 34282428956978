import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Popover } from '@headlessui/react'
import { Link } from 'gatsby'
import { LogoIcon } from '../Logo'
import RadioMenu from '../icons/RadioMenu'
import DoubleBurger from '../icons/DoubleBurger'
import Navigate from './Navigate'
import MobileMenu from './MobileMenu'
import { PATH } from '../../config'

const mapStateToProps = ({ players }) => ({
  isPlaying: players.podcastPlayer.id,
})

const Header = ({ isPlaying, shouldShowPodcastBar }) => {
  const [showMobile, setShowMobile] = useState(false)
  const isPodcastBarShow = isPlaying && shouldShowPodcastBar
  return (
    <div className="relative overflow-hidden">
      <div
        className={`w-full ${
          isPodcastBarShow ? 'h-[175px] md:h-[130px]' : 'h-[70px]'
        } `}
      ></div>
      <div
        className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full"
        aria-hidden="true"
      >
        <div className="relative mx-auto h-full max-w-7xl"></div>
      </div>
      <div className="relative">
        <div className="py-5">
          <nav
            className="relative gap-4 flex items-center justify-between sm:h-10"
            aria-label="Global"
          >
            <Link to={PATH.main} className="w-max flex items-start">
              <RadioMenu />
              <LogoIcon className="fill-logo-blue w-[122px] ml-4" />
            </Link>

            <Navigate
              className="lg:flex hidden gap-10 text-xs font-medium justify-center"
              linkClassName="text-sm border-solid hover:text-dark-blue hover:border-b-[2px] pb-4 border-[#0968D8]"
            />

            <button
              className="cursor-pointer flex lg:hidden"
              onClick={() => setShowMobile(true)}
            >
              <DoubleBurger />
            </button>
            <Link
              to={PATH.supportus}
              className="hidden lg:inline-flex font-normal items-center px-4 py-2 lg:px-10 lg:py-2.5 border-1 border-transparent text-sm text-brown rounded-full bg-yellow hover:bg-white hover:border-yellow focus:outline-none"
            >
              Підтримати
            </Link>
          </nav>
        </div>
      </div>
      {showMobile ? <MobileMenu setShowMobile={setShowMobile} /> : null}
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default connect(mapStateToProps)(Header)
